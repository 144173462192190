@font-face {
    font-family: "WorkSansSemiBold";
    src: local("WorkSansSemiBold"),
    url("./fonts/WorkSans/WorkSans-SemiBold.ttf") format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: "WorkSansRegular";
    src: local("WorkSansRegular"),
    url("./fonts/WorkSans/WorkSans-Regular.ttf") format("truetype");
    font-weight: 200;
}
.container {
    display: flex;
    flex-direction: row;
    }
    .bar{
    display:flex; 
    flex-direction: column;
    width: 4rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    justify-content: center;
    }
    .circle {
      border-radius: 100%;
      padding: 1rem;
      background: #73AD21;
      width: 2rem;
      height: 2rem;
      text-align:center;
      font-size: 2rem;
      vertical-align: middle;
    }
    .line
    {
        border-top: 2px solid #bbb;
    }
    .center
    {
        text-align:center;
        font-size: 1rem;
    /*font-family: "Roboto", "Helvetica", "Arial", sans-serif;*/
    /*font-weight: 400;*/
    line-height: 1.5;
    letter-spacing: 0.00938em;
    }